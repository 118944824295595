.hide{
  display: none;
}
.img{
  height: 200px;
  width: 200px;
}
.text-center{
  text-align: center;
}
.login-wrapper .login-btn {
  background-color: #354a5d;
}
.c-sidebar.c-sidebar-fixed {
  background: #364b5d;
}
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle, .c-sidebar .c-sidebar-nav-link.c-active {

  background: #26b7b7;
}
section.pricing {
  background: none;
  background: none;
}
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover, .c-sidebar .c-sidebar-nav-link:hover {
  color: #fff;
  background: #26b7b7;
}
.btn-primary {
  color: #fff;
  background-color: #354a5d;
  border-color: #364b5d;
  opacity: 1 !important;
}
section.pricing .card {
  border: 1px solid #364b5d;
  min-height: 430px;
  margin-bottom: 30px !important;
}
section.pricing .container {
  width: 100%;
  max-width: 100%;
}
.card.most-popuplar::after {
  content: 'Most Popular';
  position: absolute;
  top: -23px;
  background: #26b7b7;
  color: #fff;
  padding: 10px 20px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  left: 31%;
  border-radius: 5px;
}
.btn-primary:hover {
  color: #fff;
  background-color: #3c547d;
  border-color: #3c547d;
}
.card-header * {
  color: #fff !important;
}
.card-header {
  background: #364b5d;
  color: #fff !important;
}
.c-sidebar .c-sidebar-brand {
  color: #fff;
  background: #fff;
  border-right: 1px solid #ddd !important;
}
@import url(https://fonts.googleapis.com/css?family=Montserrat);
body {
  position: relative;
  width: 100%;
  height: 100vh;
  font-family: Montserrat;
}

.wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text {
  color: #fbae17;
  display: inline-block;
  margin-left: 5px;
}

.bounceball {
  position: relative;
  display: inline-block;
  height: 37px;
  width: 15px;
}
.bounceball:before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fbae17;
  transform-origin: 50%;
  -webkit-animation: bounce 500ms alternate infinite ease;
  animation: bounce 500ms alternate infinite ease;
}

@-webkit-keyframes bounce {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: 15px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}

@keyframes bounce {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: 15px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}
.load-image {
  margin-left: -45px !important;
  margin-top: -6px !important;
}
.cancel_subscription{
  cursor: pointer;
  color: blue;
}
.bg-gradient-info {
  background: #26b7b7!important;
  background: linear-gradient(
    45deg
    ,#26b7b7,#1d8686)!important;
  border-color: #2982cc!important;
}
.payment-card {
  width: 100%;
  max-width: 100%;
}
.visited {
  background: #364b5d !important;
  color: #fff!important;
}
.visited a {
  color: #fff;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #26b7b7;
  border-color: #26b7b7;
}
.table-striped tr:first-child th:nth-child(1){
  border:1px transparent !important;
}
.payment-card {
  max-width: 100% !important;
}

.p-details {
  border-bottom: 2px solid #ddd;margin-bottom: 15px;    padding-bottom: 8px;text-transform: uppercase;
}
.title-table{
  max-width: 400px;display: block;
}
.branding {
  text-transform: uppercase;text-align: center;font-weight: bold;
}
.branding-header {
  height: 65px;
}
.img-div{
  min-height: 447px;
  text-align: center;
}
.img-div-b{
  margin-bottom: 20px;
}

.c-sidebar.c-sidebar-fixed {
  background: #fff;
}
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle, .c-sidebar .c-sidebar-nav-link.c-active {
  background: #374c5f;
  border-right: 5px solid #33bbbb !important;
  color: #fff;
}
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle, .c-sidebar .c-sidebar-nav-link.c-active svg {
  color: #fff !important;
}
.c-sidebar .c-sidebar-nav-dropdown-toggle, .c-sidebar .c-sidebar-nav-link {
  color: #000;
  background: transparent;
  font-weight: bold;
}
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon, .c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon {
  color: #000;
}
.c-sidebar .c-sidebar-minimizer {
  background-color: rgb(55 76 95);
}
.c-footer {
  color: #fff;
  background: #374c5f;
  border-top: none;
  text-align: center;
  justify-content: center;
  width: 100%;
}
h4.heading {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  color: #374c5f;
}
.text {
  color: #23a6a6;
  margin-left: 5px;
}
.plan-name, .plan-price {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.pricing .cancel_subscription, .pricing a {
  color: #26b7b7;
  text-transform: lowercase;
}
.payment-card span.detail {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.payment-card span.detail {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 2px solid #374c5f;
  padding-bottom: 10px;
}
.c-wrapper:not(.c-wrapper-fluid) .c-body {

  background: #fff;
}
.c-sidebar.c-sidebar-dark.c-sidebar-lg-show.c-sidebar-fixed {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);
}
.card-header {
  background: #364b5d;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
}
.form-control {
  display: block;
  width: 100%;
  height: auto;
  padding: 10px 15px;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  color: #768192;
  background-color: #fff;
  border: 2px solid #374c5f;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  height: auto;
}
label {
  display: inline-block;
  margin-bottom: .5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.card-header {
  background: #374c5f;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
}
.card-header, .card-header * {
  color: #fff !important;
}
.card {


  border: 2px solid #374c5f;
}
.c-sidebar .c-sidebar-nav-dropdown.c-show {
  background: rgb(55 76 95);
}
table.table.table-striped textarea {
  padding: 15px;
  height: 190px;
  border: 2px solid #3bb;
  border-radius: 5px;
  width: 100%;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff !important;
}
.table-striped tbody tr.visited {
  background: #f4f4f5!important;
  color: #000 !important;
}
.table-striped tbody tr.visited a {
  color: #000 !important;
}
.table-striped tbody i.fa.fa-copy {
  width: 30px;
  height: 30px;
  background: #374c5f;
  color: #fff;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
}
.table td:last-child a, .table td:last-child i {
  width: 30px;
  height: 30px;
  background: #374c5f;
  color: #fff;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  display: inline-block;
}
.plan{
  text-transform: none !important;
}

input[type=file] {
  color: transparent !important;
}
input[type=file]::after {
  content: "No file Selected ";
  color: black;
  margin-left: 10px;
}
.card.most-popuplar:after {
  content: "Most Popular";
  position: absolute;
  top: -23px;
  background: #26b7b7;
  color: #fff;
  padding: 10px 20px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  left: 10%;
  border-radius: 5px;
  width: 80%;
  text-align: center;
}
.power-stripe{
  height: 75px;
}
.pricing .card:hover {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 30%);
}
.pricing .btn {
  margin-top: 30px !important;
}
.border-none{
  border: transparent !important;
}
.text-muted-extended{
  font-size: 12px !important;
}
.thankyou-icon {
  font-size: 80px;
  text-align: center;
}
.cpointer{
  cursor: pointer;
}
.card-header small {
  text-transform: none;
  display: block;
}
.card label {
  margin-top: 10px;
  letter-spacing: 0;
  text-transform: none;
}
button.btn.btn-primary.btn-sm {
  display: flex;
  align-items: center;
}
button.btn.btn-primary.btn-sm svg {
  margin: 0 5px 0 0;
}
.table-head{
  vertical-align: middle;
  overflow: hidden;
}
.odd{
  background: lightgray;
}
.integration-icon{
  margin-right: 16px !important;
  font-size: 18px;
  margin-left: 5px;
  color: #374c5f ;

}
.c-active .integration-icon{
  color: #FFFFFF;
}
.p10{
  padding: 10px !important;
}
.logo-color{
  background: #26b7b7!important;
  border: #26b7b7!important
}
@media screen and (min-width:768px) and (max-width:1280px){

  .bg-gradient-info {
    font-size: 12px;
  }
  .card.most-popuplar:after {

    left: 5%;
    border-radius: 5px;
    width: 90%;
    text-align: center;
    font-size: 12px;
  }
  .pricing .card-price {
    font-size: 2rem;
  }
  .pricing .card-body {
    padding: 20px 10px;
  }
  .payment-card {
    padding: 15px !important;
  }
  .plan-name, .plan-price {
    font-size: 18px;
    font-weight: 700;
  }
}

@media screen and (min-width:320px) and (max-width:767px){
  .pricing .card-body {
    padding: 20px 10px;
  }
  .payment-card {
    padding: 15px !important;
  }
  .plan-name, .plan-price {
    font-size: 18px;
    font-weight: 700;
  }
}
